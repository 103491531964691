import React from 'react';
import img from '../assets/images/items/skunk3.png'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'

const ComingSoon = () => {
    return (
        <div className='page-ComingSoon'>
            <Header />
            <section className="tf-section page-title">
                <div className="container">
                    <div className="col-md-12">
                        <div className="page-title__body rm">
                            <div className="block-text pt-12">
                                <h2 className="sub-title mb-20">ComingSoon!</h2>
                                <p className="fs-24 mb-33" >Whitelist Minting Begins <br /> June 22nd 2022 </p>
                                <h2 className="sub-title mb-30" >Enter Plot Giveaway!</h2>
                                <p className="fs-24 mb-33" >35 Silver Plots in TCG World Metaverse Will be Given <br/> to Random Winners during Mint Period! </p>
                            </div>
                            <img className="s1" src={img} alt="SkunkzNFT" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="tf-section ComingSoon">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="block-text center">
                                <h1 className="heading-bg" data-aos="fade-in" data-aos-duration="1000"><span>Mint</span></h1>
                                <h5 className="sub-title mb-10" data-aos="fade-up" data-aos-duration="1000">Mint Details</h5>
                                <h3 className="title mb-28" data-aos="fade-up" data-aos-duration="1000">Check Back Soon For<br /> Public Mint</h3>
                            </div>
                        </div>
                    </div>
                   
                </div>
                
            </section>
            <Footer />
        </div>
    );
}

export default ComingSoon;