const dataRoadMap = [
    {
        time: 'June 22, 2022',
        title: 'Whitelist Mint Begins',
        desc: 'Minting open for Phase 1 Whitelisted wallets',
        class: 'left'
    },
    {
        time: 'June 25th, 2022',
        title: 'Public Mint',
        desc: 'CryptoSkunkz Minting opens to the public',
        class: 'right mt-223'
    },
    {
        time: 'September 1st, 2022',
        title: 'Royal Skunkz',
        desc: 'Royal Skunk Club kicks off - hold 12 Skunkz to gain entry!',
        class: 'left mt--23'
    },
    {
        time: 'TBA',
        title: 'Skunk Village',
        desc: 'Building begins for Skunk Village Headquarters within TCG World Metaverse',
        class: 'right mt-200'
    },
    {
        time: 'Q1 2023',
        title: 'CryptoSkunkz Avatar',
        desc: 'Design phase begins for development of a CryptoSkunkz avatar and utility for use within TCG World Metaverse',
        class: 'left mt-200'
    },

]

export default dataRoadMap;