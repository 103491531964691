import React , { useState ,useEffect } from 'react';
import { Link } from 'react-router-dom';
import './footer.scss';
import logo from '../../assets/fake-data/logo';

const Footer = () => {

    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
  
    useEffect(() => {
      const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      window.addEventListener("scroll", toggleVisibility);
  
      return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <>
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="footer__body">
                    
                        
                       
                        <ul className="social">
                            <li data-aos="fade-up" data-aos-duration="1000"><a href="https://t.me/cryptoskunkz" target="_blank"><i className="fab fa-telegram"></i></a></li>
                            <li data-aos="fade-up" data-aos-duration="1200"><a href="https://twitter.com/skunkznft" target="_blank"><i className="fab fa-twitter"></i></a></li>
                           
                          
                         
                        </ul>
                       
                    </div>
                    <div className="footer_bottom">
                        <p className="fs-16">Copyright © 2022, CryptoSkunkz NFT  </p>
                        <ul>
                        
                        </ul>
                    </div>
                    
                </div>
            </div>
        </footer>
        {
            isVisible && 
            <Link onClick={scrollToTop}  to='#' id="scroll-top"></Link>
        }</>
        
    );
}

export default Footer;
