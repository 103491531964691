
const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/',
    
    },
    {
        id: 2,
        name: 'Story',
        links: '/lore'
    },
    {
        id: 3,
        name: 'SkunkVillage',
        links: '/SkunkVillage'
    },
    {
        id: 4,
        name: 'Collection',
        links: 'https://opensea.io/collection/cryptoskunkz' 
    },
    {
        id: 5,
        name: 'Roadmap',
        links: '/road-map'
    },

    
]

export default menus;