import React , { useState } from 'react';
import { Link } from 'react-router-dom'
import ButtonOne from '../button/ButtonOne';

const Action = () => {

    const [data] = useState(
        {
            title: 'Come Get your Skunkz!',
            desc: '',
        }
    )

    return (
        <section className="tf-section action">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="action__body" data-aos="fade-up">
                            <div className="block-text">
                                <h3 className="mb-13">{data.title}</h3>
                                <p className="fs-21 mb-7">{data.desc}</p>
                            </div>
                            <div className="button">
                                    <ButtonOne />
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Action;