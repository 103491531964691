import React, { Component } from 'react';
import Home01 from './Home01';
import Lore from './Lore';
import SkunkVillage from './SkunkVillage';
import Team from './Team';
import RoadMap from './RoadMap';
import ComingSoon from './ComingSoon';



   
 

const routes = [
  { path: '/', component: <Home01 />},
  { path: '/lore', component: <Lore />},
  { path: '/skunkvillage', component: <SkunkVillage />},
  { path: '/team', component: <Team />},
  { path: '/road-map', component: <RoadMap />},
  { path: '/ComingSoon', component: <ComingSoon />},
]

export default routes;