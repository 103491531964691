import React , { useState } from 'react';
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from "swiper";

import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'

import img from '../assets/images/items/tcgmetaverse.png'
import imgb from '../assets/images/items/tcgmetaverse.png'

import img1 from '../assets/images/items/skunk1.png'
import img2 from '../assets/images/items/skunk2t.png'
import img3 from '../assets/images/items/skunk3t.png'
import img4 from '../assets/images/items/skunk5.png'
import Action from '../components/layouts/Action';


const SkunkVillage = () => {

    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    const [data] = useState([
        {
            img: img1
        },
        {
            img: img2
        },
        {
            img: img3
        },
        
    ])
    return (
        <div className='skunkvillage'>
            <Header />
            <section className="tf-section page-title">
                <div className="container">
                    <div className="col-md-12">
                        <div className="page-title__body rm">
                            <div className="block-text pt-12">
                                <h2 className="sub-title mb-20">Skunk Village</h2>
                                <p className="fs-24 mb-33" >Join the CryptoSkunkz in Skunk Village <br /> Exclusive area within TCG World Metaverse </p>
                            </div>
                            <img className="s1" src={img} alt="SkunkzNFT" /> 
                            
                        
                           
                            
                        </div>
                        
                    </div>
                </div>
            </section>

            <section className="tf-section collection">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="block-text center">
                                <h1 className="heading-bg" data-aos="fade-in" data-aos-duration="1000"><span>Royal</span></h1>
                                <h5 className="sub-title mb-10" data-aos="fade-up" data-aos-duration="1000">Royal Skunkz Club</h5>
                                <h3 className="title mb-28" data-aos="fade-up" data-aos-duration="1000">12 Skunkz provides massive <br /> Utility</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-50">
                        <div className="col-xl-7 col-md-12">
                            <div className="group-image">

                            <Swiper 
                                style={{
                                "--swiper-navigation-color": "#fff",
                                "--swiper-pagination-color": "#fff",
                                }}
                                spaceBetween={10}
                                navigation={true}
                                thumbs={{ swiper: thumbsSwiper }}
                                modules={[FreeMode, Navigation, Thumbs]}
                                className="gallery-top"
                            >
                                
                                {
                                    data.map((data,index) => (
                                        <SwiperSlide key={index}>
                                            <div className="item bg-2"><img src={data.img} alt="SkunkzNFT" /></div>
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
                            <Swiper
                                onSwiper={setThumbsSwiper}
                                spaceBetween={10}
                                slidesPerView={4}
                                freeMode={true}
                                watchSlidesProgress={true}
                                modules={[FreeMode, Navigation, Thumbs]}
                                className="gallery-thumbs"
                            >
                                {
                                    data.map((data,index) => (
                                        <SwiperSlide key={index}>
                                            <div className="item bg-2"><img src={data.img} alt="SkunkzNFT" /></div>
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
        
                            </div>
                        </div>
                        <div className="col-xl-5 col-md-12">
                            <div className="block-text">
                                <h3 className="fs-42 mb-31">Other Utilities</h3>
                                <p className="fs-18">Not only are the CryptoSkunkz some of the coolest NFTs around, they provide the holders with utility like none other! </p>
                                <h3 className="fs-30 mt-37">Holders Receive</h3>
                                <ul className="nft">
                                    <li><i className="fa fa-arrow-right"></i><span>Marketing Discounts</span></li>
                                    <li><i className="fa fa-arrow-right"></i><span>Passive Income</span></li>
                                    <li><i className="fa fa-arrow-right"></i><span>Metaverse Social Status</span></li>
                                    <li><i className="fa fa-arrow-right"></i><span>And More!</span></li>
                                </ul>
                               
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
            <Action />
            <Footer />
        </div>
    );
}

export default SkunkVillage;
