import React from 'react';
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import dataPartners from '../assets/fake-data/data-partners';
import dataPortfolio from '../assets/fake-data/data-portfolio';
import dataTeam from '../assets/fake-data/data-team';
import Action from '../components/layouts/Action';
import Partners from '../components/layouts/Partners';
import Portfolio from '../components/layouts/Portfolio';
import Team2 from '../components/layouts/Team2';
import SliderOne from '../components/slider/SliderOne';

const Home01 = () => {
    return (
        <div>
            <Header />
            <SliderOne />
            <Partners data={dataPartners} />
            <Team2 data={dataTeam} />
            
           
            <Footer />
        </div>
    );
}

export default Home01;
