import React from 'react';
import { Link } from 'react-router-dom';

import './button.scss';

const ButtonOne = () => {
  return (
    <a href="https://dapp.cryptoskunkz.com/" target="_blank" className="btn-action"  >Mint Now</a>
  );
}

export default ButtonOne;
