import React , { useState } from 'react';
import { Link } from 'react-router-dom'

import item from '../../assets/fake-data/item';

const AboutTwo = () => {

    const [data] = useState(
        {
            subtitle: 'Lore of the Skunkz',
            title: 'Where did they come from?',
            desc1: 'The story of the CryptoSkunkz has yet to be written! Our origin has baffled scientists for a millenia!',
            desc2: 'We have challenged our amazingly brilliant CryptoSkunkz community to research and interpret the history and myths behind the Skunkz. A contest has emerged to award the top 2 community members who can transcribe a proper Lore of the Skunkz!',
        }
    )

  return (
    <section className="tf-section section-about" id='about'>
        <div className="container">
            <div className="row reverse">
                <div className="col-xl-7 col-md-12">
                    <div className="group-image">
                        <div className="left">
                            <div className="item bg-1 big"><img src={item.lore3} alt="SkunkzNFT" /></div>
                        </div>
                        <div className="right">
                            <div className="item bg-2 small"><img src={item.lore4} alt="SkunkzNFT" /></div>
                            <div className="item bg-3"><img src={item.lore5} alt="SkunkzNFT" /></div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-5 col-md-12">
                    <div className="block-text pt-12">
                        <h5 className="sub-title mb-10" data-aos="fade-up" data-aos-duration="1000">{data.subtitle}</h5>
                        <h3 className="title mb-58" data-aos="fade-up" data-aos-duration="1000">{data.title}</h3>
                        <p className="fs-21 mb-33" data-aos="fade-up" data-aos-duration="1000">{data.desc1}</p>
                        <p className="fs-21 mb-33" data-aos="fade-up" data-aos-duration="1000">{data.desc2}</p>
                        <a href="https://t.co/74QzTVyOoX" target="_blank" className="btn-action style-2"  data-aos="fade-up" data-aos-duration="1200">Join the Contest</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
}

export default AboutTwo;