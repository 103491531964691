import img1 from '../images/partners/logo-01.png'
import img2 from '../images/partners/logo-02.png'


const dataPartners = [
    {
        img: img1,  
        img2: img2,   
    },
    
    
   
    
]

export default dataPartners;