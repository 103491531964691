import img1 from '../images/team/team-01.jpg'



const dataTeam = [
    {
        img: img1,
        name:'CryptoBull',
        position: 'Founder & CEO',
        telegram: 'https://t.me/cryptowaltt',
        twitter: 'https://twitter.com/Meaningful_Much'
    },
  
]

export default dataTeam;
