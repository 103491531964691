import React from 'react';
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import img from '../assets/images/items/lore.png'
import AboutTwo from '../components/layouts/AboutTwo';

const Lore = () => {
    return (
        <div className='about'>
            <Header />
            <section className="tf-section page-title">
                <div className="container">
                    <div className="col-md-12">
                        <div className="page-title__body ab">
                            <div className="block-text pt-12">
                                <h2 className="sub-title mb-20">Skunkz Lore</h2>
                                <p className="fs-24 mb-33" >Welcoming the story of our beloved <br /> CryptoSkunkz </p>
                            </div>
                            <img src={img} alt="SkunkzNFT" />
                        </div>
                    </div>
                </div>
            </section>
            <AboutTwo />
            <Footer />
        </div>
    );
}

export default Lore;